import { Component, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as SharedSelectors from 'src/app/redux/shared/shared.selector';
import { Observable } from 'rxjs';
import { LoadingState } from './models/loading-state';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  private translate = inject(TranslateService);
  private store = inject(Store);

  globalLoading$: Observable<LoadingState>;
  globalLoadingText$: Observable<string>;
  loadingState = LoadingState;

  constructor() {
    this.translate.addLangs(['en', 'de', 'nl']);
    this.translate.setDefaultLang('de');
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|de|nl/) ? browserLang : 'de');
    localStorage.setItem('language', this.translate.currentLang);
    this.globalLoading$ = this.store.select(SharedSelectors.selectLoading);
    this.globalLoadingText$ = this.store.select(SharedSelectors.selectLoadingText);
  }
}
