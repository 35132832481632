export enum MainRoutes {
  login = 'login',
  register = 'register',
  admin = 'admin',
}

export enum AdminRoutes {
  orders = 'orders',
  ordersArchive = 'orders/archive',
  tables = 'tables',
  menu = 'menu',
  menuLanguages = 'menu/languages',
  settings = 'settings',
  help = 'help',
}
