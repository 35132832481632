import { Injectable, inject } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private toastController = inject(ToastController);
  private translateService = inject(TranslateService);

  async showToast(message: string, duration = 2000, cssClass = '') {
    const toast = await this.toastController.create({
      message,
      duration,
      position: 'bottom',
      cssClass,
    });
    toast.present();
  }

  async showToastTranslate(key: string, duration = 2000, cssClass = '') {
    const message = this.translateService.instant(key);
    await this.showToast(message, duration, cssClass);
  }
}
