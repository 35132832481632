export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAgvVza5dVQQWyy9v0VvFY5tjL6vP2Yg3s',
    authDomain: 'ordito-management-d.firebaseapp.com',
    databaseURL: 'https://ordito-management-d.firebaseio.com',
    projectId: 'ordito-management-d',
    storageBucket: 'ordito-management-d.appspot.com',
    messagingSenderId: '410305795755',
    appId: '1:410305795755:web:57d13d7b24277278ebc9ee'
  },
  mergeport: {
    providerId: 'd3d411f1-9481-40aa-8a0a-54079cb4231e'
  },
  guestAppRootUrl: 'https://ordito-guest-d.web.app',
  version: 'develop',
};
