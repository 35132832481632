import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import * as SharedActions from './shared.actions';
import { LoadingState } from 'src/app/models/loading-state';
import { Restaurant } from 'src/app/shared/models/restaurant';
import firebase from 'firebase/compat/app';
import { immerOn } from 'ngrx-immer/store';
import { Dish, LanguageCode } from 'src/app/shared/models/dish';

export const sharedFeatureKey = 'shared';

export interface SharedState {
  loading: LoadingState;
  loadingText: string;
  restaurant: Restaurant;
  user: firebase.User,
  articles: Dish[],
}

const initialState: SharedState = {
  loading: LoadingState.idle,
  loadingText: '',
  restaurant: null,
  user: null,
  articles: [],
};

export const sharedReducer: ActionReducer<SharedState, Action> = createReducer(
  initialState,
  on(SharedActions.setLoading, (state, { loading, loadingText }): SharedState => ({ ...state, loading, loadingText })),
  on(SharedActions.setRestaurant, (state, { restaurant }): SharedState => ({ ...state, restaurant })),
  immerOn(SharedActions.addLanguageToRestaurant, (state, { languageCode }) => {
    if (state.restaurant.languages) state.restaurant.languages.push(languageCode);
    else state.restaurant.languages = [languageCode];
  }),
  immerOn(SharedActions.removeLanguageFromRestaurant, (state, { languageCode }) => {
    state.restaurant.languages = state.restaurant.languages.filter(code => languageCode !== code);
  }),
  on(SharedActions.setUser, (state, { user }): SharedState => ({ ...state, user })),
  on(SharedActions.logout, (): SharedState => ({ ...initialState })),
  on(SharedActions.setRestaurantArticles, (state, { articles }): SharedState => ({ ...state, articles })),
  immerOn(SharedActions.updateArticleTranslation, (state, { articleId, translation, languageCode }) => {
    const article = state.articles.filter(a => a.id === articleId).at(0);
    console.log(article.translation)
    if (!article) return;
    if (article.translation) article.translation[languageCode] = translation;
    else {
      const newTranslationMap = new Object;
      newTranslationMap[languageCode] = translation;
      article.translation = newTranslationMap;
    }
  }),
);

// TODO: use Entitystate for articles