import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { showToast } from './core.actions';
import { ToastService } from '../services/toast.service';
import { map } from 'rxjs';

@Injectable()
export class CoreEffects {

  private actions$ = inject(Actions);
  private toastService = inject(ToastService);
  
  showToast$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(showToast),
        map(({ key }) => this.toastService.showToastTranslate(key))
      );
  }, { dispatch: false });
}
