import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { appEffects, appReducers, metaReducers } from "./core.reducer";
import { EffectsModule } from "@ngrx/effects";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forRoot(appReducers, { metaReducers }),
    EffectsModule.forRoot(appEffects),
  ],
  declarations: [],
})

// read about for singletons forRoot()
// https://angular.io/guide/singleton-services
export class ReduxModule {
  constructor(@Optional() @SkipSelf() parentModule: ReduxModule) {
    if (parentModule) {
      throw new Error('ReduxModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<ReduxModule> {
    return {
      ngModule: ReduxModule,
    };
  }
}