import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, from, map, of, switchMap, tap } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ApiService } from 'src/app/services/api.service';
import { AlertController } from '@ionic/angular';
import * as SharedActions from './shared.actions';
import * as CoreActions from '../core.actions';
import { Router } from '@angular/router';
import { AdminRoutes, MainRoutes } from 'src/app/app.router';
import { Store } from '@ngrx/store';
import { selectRestaurant, selectRestaurantAtricles, selectRestaurantId } from './shared.selector';

@Injectable()
export class SharedEffects {

  private store = inject(Store);
  private router = inject(Router);
  private actions$ = inject(Actions);
  private apiService = inject(ApiService);
  private fireAuth = inject(AngularFireAuth);
  private alertController = inject(AlertController);

  login$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SharedActions.login),
        switchMap(({ email, password }) => from(this.fireAuth.signInWithEmailAndPassword(email, password)).pipe(
          map(userCredentials => {
            if (!userCredentials.user.emailVerified) {
                return SharedActions.verifyEmail({ userCredentials: Object.freeze(userCredentials) });
            }
            return SharedActions.setUser({ user: Object.freeze(userCredentials.user) });
          }),
          catchError(() => of(CoreActions.showToast({ key: 'Auth.LOGIN_FAILED' })))
        )
      ));
  });

  loginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedActions.setUser),
      map(({ user }) => SharedActions.loadRestaurantByUserId({ id: user.uid }))
    );
  });

  loadRestaurantByUserId$ = createEffect(() => {
      return this.actions$.pipe(
      ofType(SharedActions.loadRestaurantByUserId),
      switchMap(({ id }) => this.apiService.getRestaurantsForUser(id).pipe(
          map(resaurants => {
              const restaurant = resaurants[0]
              if (!resaurants) {
                  return CoreActions.showToast({ key: 'Auth.NO_RESTAURANT_LINKED' });
              }
              return SharedActions.setRestaurant({ restaurant })
          }),
          tap(() => this.router.navigate([`${MainRoutes.admin}/${AdminRoutes.orders}`])),
          catchError(() => of(CoreActions.showToast({ key: 'Auth.LOGIN_FAILED' })))
      )));
  });

  loadRestaurantByRestaurantId$ = createEffect(() => {
    return this.actions$.pipe(
    ofType(SharedActions.loadRestaurantByRestaurantId),
    switchMap(({ id }) => this.apiService.getRestaurant(id).pipe(
        map(restaurant => SharedActions.setRestaurant({ restaurant })),
        catchError(() => of(CoreActions.showToast({ key: 'Auth.NO_RESTAURANT_LINKED' })))
    )));
});

  loadRestaurantArticles$ = createEffect(() => {
    return this.actions$.pipe(
    ofType(SharedActions.loadRestaurantArticles),
    concatLatestFrom(() => this.store.select(selectRestaurantId)),
    switchMap(([, resaurantId]) => this.apiService.getMenu(resaurantId).pipe(
        map(articles => SharedActions.setRestaurantArticles({ articles })),
        catchError(() => of(CoreActions.showToast({ key: 'Auth.LOGIN_FAILED' })))
    )
    ));
});

  addLanguageToRestaurant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedActions.addLanguageToRestaurant),
      concatLatestFrom(() => this.store.select(selectRestaurant)),
      switchMap(([, restaurant]) => from(this.apiService.updateRestaurant(restaurant)).pipe(
        catchError(() => of(CoreActions.showToast({ key: 'Failed to update restaurant' })))
      )
    ));
  }, { dispatch: false });

  removeLanguageFromRestaurant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedActions.removeLanguageFromRestaurant),
      concatLatestFrom(() => this.store.select(selectRestaurant)),
      switchMap(([, restaurant]) => from(this.apiService.updateRestaurant(restaurant)).pipe(
        catchError(() => of(CoreActions.showToast({ key: 'Failed to update restaurant' })))
      )
    ));
  }, { dispatch: false });

  
  updateArticleTranslation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedActions.updateArticleTranslation),
      concatLatestFrom(() => this.store.select(selectRestaurantAtricles)),
      map(([ { articleId }, articles]) => articles.filter(a => a.id === articleId).at(0)),
      concatLatestFrom(() => this.store.select(selectRestaurantId)),
      switchMap(([article, resaurantId]) => from(this.apiService.updateDish(resaurantId, article)).pipe(
        catchError(() => of(CoreActions.showToast({ key: 'Failed to update restaurant' })))
      )
    ));
  }, { dispatch: false });

  // TODO: localize
  verifyEmail$ = createEffect(() => {
      return this.actions$.pipe(
      ofType(SharedActions.verifyEmail),
      switchMap(({ userCredentials }) => from(this.alertController.create({
          header: 'Deine E-Mail-Adresse ist noch nicht aktiviert!',
          message: 'Möchtest Du eine neue Bestätigungs-Nachricht erhalten?<br>Achtung! Vielleicht landet diese Nachricht im Junk-Mail bzw. Spam-Ordner.',
          buttons: [{
            text: 'Ja',
            role: 'Ja',
            handler: (() => {
              userCredentials.user.sendEmailVerification();
              // TODO: not dispatched -> can handler be used in onDidDismiss?
              CoreActions.showToast({ key: 'Die Bestätigung-Nachricht wurde soeben an ' + userCredentials.user.email + ' versendet!' });
            })
          }, {
            text: 'Nein',
            role: 'Abbruch',
          //   handler: (() => { /* noop */ })
          }]
      }))),
      tap(modal => modal.present()),
      switchMap(modal => from(modal.onDidDismiss())),
      tap(dismiss => console.log(dismiss)),
  )}, { dispatch: false });

  logout$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SharedActions.logout),
        map(() => this.router.navigate([MainRoutes.login]))
      );
  }, { dispatch: false });
}
