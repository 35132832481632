import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MainRoutes } from './app.router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: MainRoutes.login, loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: MainRoutes.register, loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule) },
  // authguard in routing
  { path: MainRoutes.admin, loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
