import { createAction, props } from '@ngrx/store';
import { LoadingState } from 'src/app/models/loading-state';
import { Restaurant } from 'src/app/shared/models/restaurant';
import firebase from 'firebase/compat/app';
import { Dish, LanguageCode } from 'src/app/shared/models/dish';

export const setLoading = createAction('[shared] set loading', props<{ loading: LoadingState, loadingText: string }>());
export const login = createAction('[shared] login', props<{ email: string, password: string }>());
export const logout = createAction('[shared] logout');
export const setUser = createAction('[shared] set user', props<{ user: firebase.User }>());
export const verifyEmail = createAction('[shared] verify email', props<{ userCredentials: firebase.auth.UserCredential }>());

export const loadRestaurantByUserId = createAction('[shared] load restaurant by userId', props<{ id: string; }>());
export const loadRestaurantByRestaurantId = createAction('[shared] load restaurant by resurantId', props<{ id: string; }>());
export const setRestaurant = createAction('[shared] set restaurant', props<{ restaurant: Restaurant }>());
export const addLanguageToRestaurant = createAction('[shared] add lang to restaurant', props<{ languageCode: string }>());
export const removeLanguageFromRestaurant = createAction('[shared] remove lang from restaurant', props<{ languageCode: string }>());
export const loadRestaurantArticles = createAction('[shared] load restaurant articles');
export const setRestaurantArticles = createAction('[shared] set restaurant articles', props<{ articles: Dish[] }>());
export const updateArticleTranslation = createAction('[shared] update article translation', props<{ articleId: string, languageCode: LanguageCode, translation: string }>());