/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionReducer, ActionReducerMap, INIT, MetaReducer } from '@ngrx/store';
import { CoreEffects } from './core.effects';
import { SharedState, sharedFeatureKey, sharedReducer } from './shared/shared.reducer';
import { SharedEffects } from './shared/shared.effects';
import { RECOMPUTE } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ROOT_EFFECTS_INIT } from '@ngrx/effects';

export interface RootState {
  [sharedFeatureKey]: SharedState;
}

export const metaReducers: MetaReducer<any>[] = [debug, localStorageSyncReducer];

export const appReducers: ActionReducerMap<RootState> = {
  [sharedFeatureKey]: sharedReducer,
};

export const appEffects = [
  CoreEffects,
  SharedEffects,
];

// Meta reducers, called before actual reducers
// log all actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    const ignoredActions = [INIT, ROOT_EFFECTS_INIT, RECOMPUTE]
    if (!ignoredActions.includes(action.type)) {
      // console.log('state ->', state, '\n action ->', action.type, action);
    }
    return reducer(state, action);
  };
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: [sharedFeatureKey], rehydrate: true })(reducer);
}
