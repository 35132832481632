import { createFeatureSelector, createSelector } from '@ngrx/store';
import { sharedFeatureKey, SharedState } from './shared.reducer';

const selectShared = createFeatureSelector<SharedState>(sharedFeatureKey);
export const selectLoading = createSelector(selectShared, (state: SharedState) => state.loading);
export const selectLoadingText = createSelector(selectShared, (state: SharedState) => state.loadingText);
export const selectRestaurant = createSelector(selectShared, (state: SharedState) => state.restaurant);
export const selectRestaurantId = createSelector(selectShared, (state: SharedState) => state.restaurant?.id);
export const selectRestaurantName = createSelector(selectShared, (state: SharedState) => state.restaurant?.name);
export const selectRestaurantAtricles = createSelector(selectShared, (state: SharedState) => state.articles);

export const selectUser = createSelector(selectShared, (state: SharedState) => state.user);
export const selectUserId = createSelector(selectShared, (state: SharedState) => state.user?.uid);
export const selectUserEmail = createSelector(selectShared, (state: SharedState) => state.user?.email);
