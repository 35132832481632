import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { Table } from '../shared/models/table';
import { Dish } from '../shared/models/dish';
import { Restaurant } from '../shared/models/restaurant';
import { Category } from '../shared/models/category';
import { Timestamp } from 'firebase/firestore';
import { Order } from '../shared/models/order';
import { RestaurantOwner } from '../shared/models/restaurantOwner';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private db = inject(AngularFirestore);
    
    tablesRef = this.db.collection<Table>('tables');

    getAllCategories() {
        return this.db.collection('categories').snapshotChanges().pipe(map(actions => {
            return actions.map(action => {
                const data = action.payload.doc.data() as Category;
                const id = action.payload.doc.id;
                return { id, ...data };
            });
        }));
    }

    getOrders(restaurantID: string) {
        return this.db.collection('orders/' + restaurantID + '/orders').snapshotChanges().pipe(map(actions => {
            return actions.map(action => {
                const data = action.payload.doc.data();
                Object.keys(data)
                    .filter(key => this.isTimestamp(data[key]))
                    .forEach(key => data[key] = data[key].toDate())
                const order = data as Order;
                const id = action.payload.doc.id;
                return { id, ...order };
            });
        }));
    }

    getNewOrders(restaurantID: string) {
        return this.db.collection('orders/' + restaurantID + '/orders').stateChanges(['added']).pipe(map(actions => {
            return actions.map(action => {
                const data = action.payload.doc.data();
                Object.keys(data)
                    .filter(key => this.isTimestamp(data[key]))
                    .forEach(key => data[key] = data[key].toDate())
                const order = data as Order;
                const id = action.payload.doc.id;
                return { id, ...order };
            });
        }));
    }

    isTimestamp(object: object): object is Timestamp {
        return (<Timestamp>object).seconds !== undefined;
    }

    orderDone(restaurantID: string, order: Order) {
        delete order.open;
        order.disabled = true;
        order.doneDate = new Date();
        this.db.collection(`orders/${restaurantID}/orders`, ref => ref.where('id', '==', order.id)).get().subscribe(orders => {
            if (!orders.empty && orders.size === 1) {
                orders.docs.at(0).ref.update({ ...order });
            } else {
                console.error(`Order with ID ${order.id} not found`);
            }
        });
    }

    deleteOrder(restaurantID: string, order: Order) {
        order.deleted = true;
        this.db.collection(`orders/${restaurantID}/orders`, ref => ref.where('id', '==', order.id)).get().subscribe(orders => {
            if (!orders.empty && orders.size === 1) {
                orders.docs.at(0).ref.update({ ...order });
            } else {
                console.error(`Order with ID ${order.id} not found`);
            }
        });
    }

    getTables(restaurantDocumentID: string) {
        return this.db.collection('tables', ref => ref.where('restaurantDocumentID', '==', restaurantDocumentID.trim()))
            .snapshotChanges().pipe(map(actions => {
                return actions.map(action => {
                    const data = action.payload.doc.data() as Table;
                    const id = action.payload.doc.id;
                    return { id, ...data };
                });
            }));
    }

    getTableById(id: string) {
        return this.tablesRef.doc(id).snapshotChanges();
    }

    updateTable(table: Table) {
        this.tablesRef.doc(table.id).update(table);
    }

    addTable(table: Table) {
        this.tablesRef.add(table);
    }

    async deleteTable(id: string) {
        await this.tablesRef.doc(id).delete();
    }

    tableCodeExists(code: string): Promise<boolean> {
        return this.db.collection('tables', ref => ref.where('tableCode', '==', code)).get().pipe(map(data => {
            return data.size > 0;
        })).toPromise();
    }

    createMenu(menu: object) {
        this.db.collection('menus').add(menu);
    }

    getMenu(restaurantID: string) {
        return this.db.collection('menus-client-app/' + restaurantID + '/dishes').snapshotChanges().pipe(map(actions => {
            return actions.map(action => {
                const data = action.payload.doc.data() as Dish;
                const id = action.payload.doc.id;
                return { id, ...data };
            });
        })
        );
    }

    updateDish(restaurantID: string, article: Dish) {
        return this.db.collection('menus-client-app/' + restaurantID + '/dishes').doc(article.id).update(article);
    }

    getRestaurantsForUser(uid: string): Observable<Restaurant[]> {
        return this.db.collection('restaurants', ref => ref.where('restaurantOwners', 'array-contains', uid))
            .snapshotChanges().pipe(
                map(actions => actions.map(action => action.payload.doc.data())),
                map(restaurants => restaurants as Restaurant[])
            );
    }

    addRestaurantOwner(owner: RestaurantOwner) {
        return this.db.collection('restaurantOwners').doc(owner.uid).set(owner);
    }

    getRestaurantOwner(restaurantOwnerID: string) {
        return this.db.collection('restaurantOwners').doc(restaurantOwnerID).get().pipe(
            map(data => data.data() as RestaurantOwner)
        );
    }

    addRestaurant(restaurant: Restaurant) {
        return this.db.collection('restaurants').add(restaurant);
    }

    getRestaurant(restaurantID: string): Observable<Restaurant> {
        return this.db.collection('restaurants').doc(restaurantID).get().pipe(map(data => {
            return data.data() as Restaurant;
        }));
    }

    updateRestaurant(restaurant: Restaurant) {
        return this.db.collection('restaurants').doc(restaurant.id).update(restaurant);
    }

    updateLoginHistory(restaurantOwnerID: string, loginHistory) {
        // return;
        this.db.collection('restaurantOwners').doc(restaurantOwnerID).update({ loginHistory }).then(() => { /* noop */});
    }
}
