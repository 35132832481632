// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-ui {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.loading-text {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,WAAA;EACA,YAAA;EACA,0CAAA;EACA,kCAAA;UAAA,0BAAA;AACJ;;AAEA;EACI,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,uBAAA;EACA,aAAA;EACA,kBAAA;EACA,kBAAA;AACJ","sourcesContent":[".block-ui {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(128, 128, 128, 0.5);\n    backdrop-filter: blur(5px);\n}\n  \n.loading-text {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: white;\n    padding: 20px;\n    border-radius: 8px;\n    text-align: center;\n}\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
